import React from "react";
import Header, { HeaderNav, Menu } from "../../Components/Header/Header";
import { Col, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import SocialIcons from "../../Components/SocialIcon/SocialIcons";

const iconData = [
  {
    color: "#fff",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#fff",
    link: "https://dribbble.com/",
    icon: "fab fa-dribbble",
  },
  {
    color: "#fff",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#fff",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
];

const SocialIconsData = [
  {
    color: "#3b5998",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#ea4c89",
    link: "https://dribbble.com/",
    icon: "fab fa-dribbble",
  },
  {
    color: "#00aced",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#fe1f49",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
  {
    color: "#0077b5",
    link: "https://www.linkedin.com/",
    icon: "fab fa-linkedin-in",
  },
];

const MyHeaders = (props) => {
  return (
    <>
      <Header topSpace={{ md: true }} type="reverse-scroll">
        <HeaderNav
          fluid="fluid"
          theme="dark"
          expand="lg"
          className="py-[0px] px-[35px] md:px-[15px] md:py-[20px] sm:px-0  one-page-navigation border-b border-[#ffffff1a]"
        >
          <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  width="111"
                  height="36"
                  src="/assets/img/logo/ub_logo.png"
                  data-rjs="/assets/img/logo/logo.png"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  width="111"
                  height="36"
                  src="/assets/img/logo/ub_logo.png"
                  data-rjs="/assets/img/logo/logo.png"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  src="/assets/img/logo/ub_logo.png"
                  data-rjs="/assets/img/logo/logo.png"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle className="order-last md:ml-[17px] w-[25px] min-h-[15px] inline-block align-middle">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-center col-auto col-lg-8">
            <Menu {...props} />
          </Navbar.Collapse>
          <Col className="col-auto col-lg-2 text-end hidden-xs px-lg-0 sm:pr-[15px] xs:!text-center">
            <SocialIcons
              theme="social-icon-style-01"
              size="xs"
              iconColor="light"
              className="justify-end"
              data={SocialIconsData}
            />
          </Col>
        </HeaderNav>
      </Header>
    </>
  );
};

export default MyHeaders;
