import React from "react";

// Libraries
import { Container, Row, Col } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";

// Components
import PortfolioClassic from "../../../Components/Portfolio/PortfolioClassic";

// Data
import {
  FilterTextileData,
  portfolioNylonData,
} from "../../../Components/Portfolio/PortfolioData";
import MyHeaders from "../../Home/MyHeaders";
import MyFooters from "../../../MyFooters";

const PortfolioNylon = (props) => {
  return (
    <div style={props.style}>
      <MyHeaders />
      {/* Parallax Section Start */}
      <div className="py-[80px] h-auto overflow-hidden md:relative md:py-[40px]">
        <Parallax
          className="lg-no-parallax absolute top-[0] w-full h-full lg:bg-cover"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(/assets/img/portfolio/nylon.jpg)`,
          }}
        ></Parallax>
        <Container className="h-full relative">
          <Row className="justify-center h-[500px] sm:h-[250px]">
            <Col
              xl={6}
              lg={6}
              sm={8}
              className="text-center flex justify-center flex-col font-serif"
            >
              {/* <h1 className="text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] mb-[15px] inline-block text-xmd leading-[20px]">Leathers Portfolio</h1> */}
              <h2 className="text-white font-medium -tracking-[1px] mb-0">
                Nylon Equipment's Portfolio
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Parallax Section End */}

      {/* Section Start */}
      <section className="pb-[130px] lg:pb-[90px] md:pb-[75px] sm:pb-[50px] bg-lightgray overflow-visible relative pt-[50px]">
        <Container>
          <Row>
            <Col xs={12} className="xs:px-0">
              <PortfolioClassic
                overlay={[
                  "#0039e3",
                  "#4132e0",
                  "#5e28dd",
                  "#741bd9",
                  "#8600d4",
                ]}
                grid="grid grid-2col xl-grid-2col lg-grid-2col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large"
                // filterData={FilterTextileData}
                data={portfolioNylonData}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}
      <MyFooters />
    </div>
  );
};

export default PortfolioNylon;
