import React, { lazy } from "react";
import FooterStyle03 from "../../Components/Footers/FooterStyle03";
// Libraries
import { Link } from "react-router-dom";
import { Col, Container, Navbar, Row, Tab, Tabs } from "react-bootstrap";
import * as Yup from "yup";
import { AnimatePresence, m } from "framer-motion";
import { Form, Formik } from "formik";
// Functions
import {
  fadeIn,
  fadeInLeft,
  fadeInRight,
  rotateInLeft,
  zoomIn,
} from "../../Functions/GlobalAnimations";

// Components
import { resetForm, sendEmail } from "../../Functions/Utilities";
import { Input } from "../../Components/Form/Form";
import FooterMenu, { Footer } from "../../Components/Footers/Footer";
import InViewPort from "../../Components/InViewPort";

// Data
import FooterData from "../../Components/Footers/FooterData";
import {
  pricingTable03MonthData,
  pricingTable03YearData,
} from "../../Components/PricingTable/PricingTableData";
import { IconWithTextData_06 } from "../../Components/IconWithText/IconWithTextData";
import { blogData } from "../../Components/Blogs/BlogData";
import { Parallax } from "react-scroll-parallax";
import { Link as ScrollTo } from "react-scroll";
import FooterStyle01 from "../../Components/Footers/FooterStyle01";
import RotateBox from "../../Components/RotateBox/RotateBox";
import TextSlider03 from "../../Components/TextSlider/TextSlider03";
import { TextSliderData03 } from "../../Components/TextSlider/TextSliderData";
import MyFooters from "../../MyFooters";
import MyHeaders from "./MyHeaders";

const IconWithText = lazy(() =>
  import("../../Components/IconWithText/IconWithText")
);

const Header = React.lazy(() =>
  import("../../Components/Header/Header").then((module) => ({
    default: module.Header,
  }))
);
const HeaderNav = React.lazy(() =>
  import("../../Components/Header/Header").then((module) => ({
    default: module.HeaderNav,
  }))
);
const Menu = React.lazy(() =>
  import("../../Components/Header/Header").then((module) => ({
    default: module.Menu,
  }))
);

const Buttons = lazy(() => import("../../Components/Button/Buttons"));
const InteractiveBanners02 = lazy(() =>
  import("../../Components/InteractiveBanners/InteractiveBanners02")
);
const ProcessStep = lazy(() =>
  import("../../Components/ProcessStep/ProcessStep")
);
const Overlap = lazy(() => import("../../Components/Overlap/Overlap"));
const PricingTable03 = lazy(() =>
  import("../../Components/PricingTable/PricingTable03")
);
const ReactCustomScrollbar = lazy(() =>
  import("../../Components/ReactCustomScrollbar")
);
const BlogMasonry = lazy(() => import("../../Components/Blogs/BlogMasonry"));
const MessageBox = lazy(() => import("../../Components/MessageBox/MessageBox"));
const SocialIcons = lazy(() =>
  import("../../Components/SocialIcon/SocialIcons")
);
const SideButtons = lazy(() => import("../../Components/SideButtons"));
const StaticInstagram = lazy(() =>
  import("../../Components/Instagram/StaticInstagram")
);
const TiltBox = lazy(() =>
  import("../../Components/FancyText/FancyText").then((module) => ({
    default: module.TiltBox,
  }))
);
const StartupPageBannerSlider = lazy(() =>
  import("../Home/Startup/StartupBanner")
);

// Filter the blog data category wise
const blogMasonryData = blogData
  .filter((item) => item.blogType === "masonry")
  .filter((item) => item.category.includes("startup"));

const iconData = [
  {
    color: "#fff",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#fff",
    link: "https://dribbble.com/",
    icon: "fab fa-dribbble",
  },
  {
    color: "#fff",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#fff",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
];

const iconWithTextData = [
  {
    icon: "line-icon-Cursor-Click2 text-[#e33f00]",
    title: "Quality Standards",
    content: "Customer Satisfaction",
  },
  {
    icon: "line-icon-Bakelite text-[#e33f00]",
    title: "Customized Products",
    content: "High quality products",
  },
  {
    icon: "line-icon-Boy text-[#e33f00]",
    title: "Delivery Commitment",
    content: "We adide deadlines",
  },
];

const RotateBoxData = [
  {
    img: "./assets/img/products/leather.jpg",
    title: "Leathers",
    subtitle: "Felling good",
    icon: "line-icon-Flick",
    btnLink: "/leathers",
    btnTitle: "Explore Leathers",
    content:
      "Our Products are crafted with premium-quality leather, ensuring durability and comfort for both horse and rider. Experience the perfect blend of elegance and functionality with our range of saddles, bridles, and accessories designed to enhance your equestrian journey.",
  },
  {
    img: "./assets/img/products/textiles.jpg",
    title: "Textiles",
    subtitle: "All Seasons",
    icon: "line-icon-Bell-2",
    btnLink: "/portfolio-textiles",
    btnTitle: "Explore Textiles",
    content:
      "Our Textile Equestrian Products offer exceptional comfort and performance, crafted from high-quality materials to ensure durability and style. Explore our collection of Horse Rugs of all seasons, saddle pads, and horse blankets designed to meet the demands of every  horse.",
  },
  {
    img: "./assets/img/products/pvc.png",
    title: "PVC Equipments",
    subtitle: "Ultimate Comfort",
    icon: "line-icon-Sun",
    btnLink: "/portfolio-pvc",
    btnTitle: "Explore PVC Equipments",
    content:
      "Our PVC  Equestrian Products combine strength and flexibility, providing a low-maintenance, weather-resistant option for riders. Discover durable bridles, reins, and halters that offer superior comfort and longevity for both horse and rider.",
  },
  {
    img: "./assets/img/products/nylon.png",
    title: "Nylon Equipments",
    subtitle: "Ultimate Comfort",
    icon: "line-icon-Sun",
    btnLink: "/portfolio-nylon",
    btnTitle: "Explore Nylon Equipments",
    content:
      "Our Nylon Equestrian Products are designed for durability and versatility, offering reliable performance in various conditions. Explore our range of nylon halters, lead ropes, and harnesses, crafted for comfort and long-lasting use.",
  },
];
const SocialIconsData = [
  {
    color: "#3b5998",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#ea4c89",
    link: "https://dribbble.com/",
    icon: "fab fa-dribbble",
  },
  {
    color: "#00aced",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#fe1f49",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
  {
    color: "#0077b5",
    link: "https://www.linkedin.com/",
    icon: "fab fa-linkedin-in",
  },
];
const UnitedBros = (props) => {
  return (
    <>
      <SideButtons />
      <MyHeaders props={props} />
      <div
        id="parallax-section"
        className="full-screen md:flex md:items-center landscape:md:h-[600px] overflow-hidden relative"
      >
        <Parallax
          className="lg-no-parallax bg-cover absolute top-[0px] left-0 md:-top-[30px] w-full h-[100vh]"
          translateY={[-40, 40]}
          style={{ backgroundImage: `url(./assets/img/banner/hero.png)` }}
        ></Parallax>
        {/* <div className="absolute h-full w-full opacity-60 top-0 left-0 bg-darkgray"></div> */}
        <div className="relative h-full w-full hero">
          <Row className="justify-end items-center h-full">
            <Col
              xl={8}
              lg={6}
              className="justify-end items-center flex md:justify-center  md:mt-[15px] sm:block"
            >
              <div className="hero-text sm:block">
                <h1 className="text-[50px] ITC font-semibold  ">
                  Defining Riding Attitude...
                </h1>
                {/* <span className="font-serif text-md uppercase tracking-[3px] text-white opacity-70 block">Experience the taste of italy</span> */}
              </div>
            </Col>
          </Row>
          <ScrollTo
            aria-label="Scrolling Link"
            href="#"
            to="about"
            offset={0}
            delay={0}
            spy={true}
            smooth={true}
            duration={800}
            className="absolute bottom-[100px] left-1/2 -translate-x-1/2 cursor-pointer"
          >
            <span className="hidden">Link for Scrolling</span>
            <i className="ti-mouse icon-small text-white up-down-ani text-[28px] inline-block"></i>
          </ScrollTo>
        </div>
      </div>

      {/* Section Start  */}
      <section
        className="bg-cover bg-center pb-[180px] lg:pb-[160px] md:py-[110px] sm:py-[50px] startup-iconwithtext"
        style={{
          backgroundImage:
            "url(./assets/img/banner/home-startup-about-bg.webp)",
        }}
      >
        <Container>
          <div className="mb-[105px] md:mb-[70px] sm:mb-[50px]">
            <Overlap className="md:mt-0">
              <Row className="justify-center">
                <Col xs={12} sm={9} lg={12} md={12}>
                  <IconWithText
                    grid="row-cols-1 row-cols-lg-3 row-cols-md-2 justify-center gap-y-10 z-10 relative"
                    className="rounded-[4px] flex"
                    theme="icon-with-text-04"
                    data={iconWithTextData}
                  />
                </Col>
              </Row>
            </Overlap>
          </div>
          <Row className="items-end overflow-hidden">
            {/* <m.div className="col-12 col-lg-3 col-md-6 order-1 text-right md:mb-[50px] md:text-center" {...fadeInRight}>
              <div className="text-[70px] text-basecolor font-serif leading-[70px] tracking-[-3px] font-semibold">175+</div>
              <span className="font-serif text-darkgray font-medium uppercase tracking-[2px] block mb-[15px] sm:mb-[5px]">Worldwide offices</span>
              <p className="w-[90%] inline-block sm:w-[60%] xs:w-full">Lorem ipsum dolor sit consectetur do eiusmod tempor incididunt</p>
            </m.div> */}
            <m.div
              className="col-12 col-lg-12 z-0 py-[10px]"
              {...{ ...fadeIn, transition: { duration: 0.6 } }}
            >
              <TiltBox>
                <h1
                  className="title cover-background inline-block font-serif mb-0 uppercase bg-darkgray font-semibold tracking-[-10px] text-[200px] lg:text-[200px] leading-[180px] xs:text-[85px] sm:text-[85px] xs:leading-[100px]"
                  style={{
                    backgroundImage: `url(./assets/img/banner/hero.png)`,
                  }}
                >
                  TRIOLOGY
                </h1>
                <span className="itc text-xlg text-darkgray tracking-[2px] font-semibold titlecase block xs:text-md">
                  It's evident that United Bros foundation is built upon three
                  solid pillars that serve as the guiding principles for
                  company's operations.
                </span>
              </TiltBox>
            </m.div>
            {/* <m.div className="col-12 col-lg-3 col-md-6 order-2 md:mb-[50px] md:text-center" {...fadeInLeft}>
              <div className="text-[70px] text-basecolor font-serif leading-[70px] tracking-[-3px] font-semibold">200+</div>
              <span className="font-serif text-darkgray font-medium uppercase tracking-[2px] block mb-[15px] sm:mb-[5px]">High skilled people</span>
              <p className="w-[90%] inline-block sm:w-[60%] xs:w-full">Lorem ipsum dolor sit consectetur do eiusmod tempor incididunt</p>
            </m.div> */}
          </Row>
        </Container>
      </section>
      {/* Section End  */}
      <InViewPort />
      {/* Section Start */}
      <section
        id="about"
        className="pb-[200px] sm:pb-[50px] sm:pt-[50px] mb-[30px] md:mb-[0px] sm:mb-0 inline-block w-full"
      >
        <Overlap>
          <m.img
            {...{ ...zoomIn, transition: { duration: 0.8 } }}
            className="mx-auto border-[10px] rounded-full p-0 shadow-black w-[170px] h-[170px] border-white box-shadow-large"
            src="./assets/img/logo/logo_trans.png"
            alt="startup"
          />
        </Overlap>
        <Container>
          <Row className="mt-20 md:mt-24">
            <m.div {...fadeIn} className="col-12 col-md-6">
              <h2 className="heading-5 font-serif font-medium leading-[46px] -tracking-[1px] w-[95%] text-[#333045] m-md-0 lg:w-full sm:leading-[32px]">
                Why business with us? Because we provide the best quality
              </h2>
            </m.div>
            <m.div
              {...{ ...fadeIn, transition: { delay: 0.6 } }}
              className="col-12 col-lg-5 offset-lg-1 col-md-6"
            >
              <p className="leading-[32px] w-[95%] mb-[25px]">
                Choose our equestrian business for unparalleled expertise,
                exceptional customer service, and top-quality products tailored
                to meet your unique needs. We prioritize your satisfaction and
                the well-being of your horses, ensuring a trusted partnership
                and a superior equestrian experience.
              </p>
              <Buttons
                ariaLabel="link for about us"
                href="#"
                className="font-semibold font-serif uppercase btn-link after:h-[1px] md:text-md md:mb-[15px] after:bg-[#333045] hover:text-darkgray text-[#c89965] hover:text-[#c89965]"
                size="xlg"
                color="orange"
                title="Explore Now"
              />
            </m.div>
          </Row>
          {/* <Row className="justify-center lg:mt-[200px] md:mt-0">
            <Overlap className="col-12 col-md-12 col-sm-8 md:mt-0 md:py-[95px] sm:py-[80px] xs:pb-0">
              <RotateBox animation={rotateInLeft} animationDelay={0.4} grid="row-cols-1 row-cols-md-2 row-cols-lg-3 gap-y-10 justify-center" data={RotateBoxData} />
            </Overlap>
          </Row> */}
        </Container>
      </section>
      {/* Section End */}

      {/* Overlap Section Start */}
      <section className="pb-[0px] bg-[#faf6f3] pt-[100px] lg:pb-[120px] md:py-[95px] sm:py-[80px]  sm:pt-[-0px]">
        <div className="products-container">
          <Row className="justify-center lg:mt-[200px] md:mt-0">
            <Overlap className="col-12 col-md-12 col-sm-8 md:mt-0 md:py-[95px] sm:py-[80px] xs:pb-0">
              <RotateBox
                animation={rotateInLeft}
                animationDelay={0.4}
                grid="row-cols-1 row-cols-md-3 row-cols-lg-4 gap-y-10 justify-center"
                data={RotateBoxData}
              />
            </Overlap>
          </Row>
        </div>
      </section>
      {/* Overlap Section End */}

      {/* Section Start */}
      <m.section
        {...{ ...fadeIn, transition: { duration: 1 } }}
        className="bg-[#f8f4f0] py-[130px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]"
      >
        <Container>
          <Row className="justify-center">
            <Col
              lg={4}
              md={6}
              className="text-center mb-[4.5rem] md:mb-16 sm:mb-12"
            >
              <div className="flex flex-row items-center justify-center text-center mb-[5px]">
                <span className="w-[25px] h-[1px] bg-[#ca943d] opacity-40"></span>
                <div className="font-serif text-xmd text-[#ca943d] px-[10px]">
                  {" "}
                  equestrian experience{" "}
                </div>
                <span className="w-[25px] h-[1px] bg-[#ca943d] opacity-40"></span>
              </div>
              <h2 className="heading-5 font-serif font-semibold text-darkgray uppercase -tracking-[1px] sm:mb-[15px]">
                EQUESTRIAN PRODUCTS
              </h2>
            </Col>
          </Row>
        </Container>
        <TextSlider03
          data={TextSliderData03}
          className="sm:px-[15px] black-move"
          carousalOption={{
            spaceBetween: 30,
            slidesPerView: 1,
            autoplay: { delay: 4500, disableOnInteraction: false },
            breakpoints: { 1199: { slidesPerView: 2 } },
          }}
        />
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <section className="bg-world relative py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] overflow-hidden">
        <Container>
          <Row className="items-center justify-center">
            <Col lg={{ span: 5, offset: 2, order: 1 }} xs={{ order: 2 }}>
              <m.img
                width={458}
                height={258}
                src="./assets/img/map.jpg"
                alt="..."
                {...{ ...zoomIn, transition: { duration: 0.7 } }}
              />
            </Col>
            <m.div
              className="md:mb-[50px] md:text-center col-xl-4 col-lg-5 col-md-7 offset-xl-1 order-lg-2 order-1"
              {...fadeIn}
            >
              <h2 className="heading-5 font-serif font-medium text-white lg:w-[95%] md:w-full">
                We have offices in different countries
              </h2>
              <p className="w-[80%] mb-[20px] md:w-full">
                Lorem ipsum is simply dummy text of the and typesetting industry
                lorem ipsum industry's standard text.
              </p>
              <Buttons
                ariaLabel="link for Get in touch"
                to="/"
                className="font-medium font-serif uppercase btn-link after:h-[2px] md:text-md md:mb-[15px] after:bg-spanishgray hover:text-spanishgray text-[#c89965] hover:text-[#c89965]"
                size="xl"
                color="orange!important"
                title="Get in touch"
              />
            </m.div>
            <div className="top-1/2 w-auto font-serif font-semibold text-[200px] text-basecolor -tracking-[10px] -left-[50px] md:hidden md:right-0 md:-bottom-[40px] absolute opacity-20 text-start md:text-center lg:block">
              world
            </div>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      <MyFooters />
    </>
  );
};

export default UnitedBros;
