const HeaderData = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Products",
    dropdown: [
      {
        title: "Leathers",
        icon: "ti-layout-accordion-separated",
        link: "/leathers",
      },
      {
        title: "Textiles",
        icon: "ti-layout-accordion-separated",
        link: "/portfolio-textiles",
      },
      {
        title: " Pvc Equipments",
        icon: "ti-layout-accordion-separated",
        link: "/portfolio-pvc",
      },
      {
        title: "Nylon Equipments",
        icon: "ti-layout-accordion-separated",
        link: "/portfolio-nylon",
      },
    ],
  },

  {
    title: "About Us",
    link: "/about-us",
  },
  {
    title: "Contact Us",
    link: "/contact-us",
  },
];

export default HeaderData;
