import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FooterMenu, { Footer } from "./Components/Footers/Footer";
import { Link } from "react-router-dom";
import SocialIcons from "./Components/SocialIcon/SocialIcons";
import FooterData from "./Components/Footers/FooterData";

const iconData = [
  {
    color: "#fff",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#fff",
    link: "https://dribbble.com/",
    icon: "fab fa-dribbble",
  },
  {
    color: "#fff",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#fff",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
];

const MyFooters = () => {
  return (
    <>
      {/* footer Start */}
      <Footer
        theme="dark"
        className="hotelresort-footer bg-[#262b35] text-[#ffffff66]"
      >
        <div className="py-[5%] lg:py-[8%] md:py-[50px]">
          <Container>
            <Row>
              <Col
                xs={12}
                lg={{ order: 0, span: 3 }}
                sm={{ order: 1, span: 6 }}
                className="md:mb-[40px] xs:mb-[25px]"
              >
                <span className="font-serif font-medium block text-white mb-[20px] mt-[5px] text-md uppercase tracking-[1px] xs:mb-[10px]">
                  About company
                </span>
                <p>
                  It’s impressive to see the journey of United Bros. and how the
                  company has evolved over the years from Zosan International
                  into a prominent manufacturer and customized solution provider
                  in the equestrian industry.
                </p>
              </Col>
              <FooterMenu
                data={FooterData.slice(0, 1)}
                xs={12}
                lg={{ order: 0, span: 2 }}
                sm={{ order: 2, span: 5, offset: 1 }}
                className=" md:mb-[40px] xs:mb-[25px]"
                titleClass="uppercase text-md"
              />
              <Col
                lg={{ span: 3, order: 0, offset: 0 }}
                sm={{ span: 5, offset: 1, order: 4 }}
                className="xs:mb-[25px]"
              >
                <span className="mb-[20px] font-serif text-md block uppercase font-medium tracking-[1px] text-white xs:mb-[10px]">
                  Get in touch
                </span>
                <p className="w-[84%] mb-[15px]">
                  82-A, Shyam Nagar, Scheme-II - C.O.D. Kanpur - 208013 - (UP) -
                  India
                </p>
                <div>+91- 512- 2367478</div>
                <div>
                  <a
                    aria-label="link for gmail"
                    className="hover:!text-[#c89965]"
                    href="mailto:equine@unitedbros.in"
                  >
                    equine@unitedbros.in
                  </a>
                </div>
              </Col>
              <Col lg={{ span: 3, order: 0 }} sm={{ span: 6, order: 3 }}>
                <span className="font-medium font-serif text-white block uppercase tracking-[1px] text-md mb-[35px]">
                  Follow us on Instagram
                </span>
                <div className="w-full inline-block">
                  <ul className="instagram-posts flex">
                    <li className="grid-item rounded p-[5px] mb-[5px]">
                      <figure className="relative overflow-hidden rounded-[3px]">
                        <a
                          href="https://www.instagram.com/"
                          target="_blank"
                          aria-label="instagram image"
                          rel="noreferrer"
                        >
                          <img
                            height="80px"
                            width="80px"
                            alt="instagram-img"
                            className="w-full rounded-[3px]"
                            src="/assets/img/social/insta_1.jpg"
                          />
                          <i className="fab fa-instagram"></i>
                        </a>
                      </figure>
                    </li>

                    <li className="grid-item rounded p-[5px] mb-[5px]">
                      <figure className="relative overflow-hidden rounded-[3px]">
                        <a
                          href="https://www.instagram.com/"
                          target="_blank"
                          aria-label="instagram image"
                          rel="noreferrer"
                        >
                          <img
                            height="80px"
                            width="80px"
                            alt="instagram-img"
                            className="w-full rounded-[3px]"
                            src="/assets/img/social/insta_2.jpg"
                          />
                          <i className="fab fa-instagram"></i>
                        </a>
                      </figure>
                    </li>

                    <li className="grid-item rounded p-[5px] mb-[5px]">
                      <figure className="relative overflow-hidden rounded-[3px]">
                        <a
                          href="https://www.instagram.com/"
                          target="_blank"
                          aria-label="instagram image"
                          rel="noreferrer"
                        >
                          <img
                            height="80px"
                            width="80px"
                            alt="instagram-img"
                            className="w-full rounded-[3px]"
                            src="/assets/img/social/insta_3.jpg"
                          />
                          <i className="fab fa-instagram"></i>
                        </a>
                      </figure>
                    </li>
                  </ul>
                  <a
                    aria-label="link about company"
                    rel="noreferrer"
                    href="https://www.instagram.com/"
                    target="_blank"
                    className="flex text-slateblue font-medium text-xs font-serif uppercase mt-[40px] items-center hover:text-white xs:!text-start"
                  >
                    <i className="fab fa-instagram text-lg mr-[10px] text-[#c89965]"></i>
                    <span className="inline-block text-[#c89965] hover:text-[#c89965]">
                      Follow instagram
                    </span>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="py-[40px] border-t border-[#ffffff1a]">
          <Container>
            <Row>
              <Col md={3} className="sm:mb-[20px]">
                <Link
                  aria-label="footer home link"
                  to="/"
                  className="sm:flex sm:justify-center"
                >
                  <img
                    alt="logo"
                    src="/assets/img/logo/ub_logo.png"
                    width="111"
                    height="36"
                  />
                </Link>
              </Col>
              <Col
                md={6}
                className="flex justify-center items-center text-center sm:mb-[20px]"
              >
                <p className="mb-0">
                  &copy; {new Date().getFullYear()} United Bros, All rights
                  reserved.
                </p>
              </Col>
              <Col md={3} className="text-right xs:text-center">
                <SocialIcons
                  size="xs"
                  theme="social-icon-style-12"
                  className="justify-end sm:justify-center"
                  iconColor="light"
                  data={iconData}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </Footer>
      {/* footer End */}
    </>
  );
};

export default MyFooters;
