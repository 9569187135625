const TextSliderData01 = [
    {
        img: "https://via.placeholder.com/1200x702",
        subtitle: "Romantic",
        title: "Romantic getaways around the world",
        content: "Lorem ipsum dolor sit consectetur adipiscing eiusmod tempor incididunt labore dolore magna ut enim.",
        buttonTitle: "Explore vacation",
        buttonLink: "#"
    },
    {
        img: "https://via.placeholder.com/1200x702",
        subtitle: "Family",
        title: "Explore incredible deals on family tours",
        content: "Lorem ipsum dolor sit consectetur adipiscing eiusmod tempor incididunt labore dolore magna ut enim.",
        buttonTitle: "Explore vacation",
        buttonLink: "#"
    },
    {
        img: "https://via.placeholder.com/1200x702",
        subtitle: "Wildlife",
        title: "Explore a wide range of wildlife packages",
        content: "Lorem ipsum dolor sit consectetur adipiscing eiusmod tempor incididunt labore dolore magna ut enim.",
        buttonTitle: "Explore vacation",
        buttonLink: "#"
    }
]


const TextSliderData02 = [
    {
        img: "https://via.placeholder.com/1050x614",
        title: 'Business',
        subtitle: ' transformation',
        content: 'Lorem ipsum dolor consectetur adipiscing mod tempor incididunt labore dolore magna ut veniam.',
        buttonTitle: "Learn more",
        buttonLink: "/page/our-services"
    },
    {
        img: "https://via.placeholder.com/1050x614",
        title: "Strategic",
        subtitle: "communication",
        content: "Lorem ipsum dolor consectetur adipiscing mod tempor incididunt labore dolore magna ut veniam.",
        buttonTitle: "Learn more",
        buttonLink: "/page/our-services"
    },
    {
        img: "https://via.placeholder.com/1920x950",
        title: "Competitors",
        subtitle: " research",
        content: "Lorem ipsum dolor consectetur adipiscing mod tempor incididunt labore dolore magna ut veniam.",
        buttonTitle: "Learn more",
        buttonLink: "/page/our-services"
    },
    {
        img: "https://via.placeholder.com/1050x614",
        title: "Consumer",
        subtitle: " research",
        content: "Lorem ipsum dolor consectetur adipiscing mod tempor incididunt labore dolore magna ut veniam.",
        buttonTitle: "Learn more",
        buttonLink: "/page/our-services"
    },
]


const TextSliderData03 = [
    {
        img: "./assets/img/products/equestrian/img_1.jpg",
        icon: "./assets/img/logo_vector.svg",
        title: "All Seasons Rugs",
        content: "Discover our range of high-performance rugs designed to provide comfort and protection for your horse throughout the year."
    },
    {
        img: "./assets/img/products/equestrian/img_2.jpg",
        icon: "./assets/img/logo_vector.svg",
        title: "Fleece Cooler Rugs",
        content: "Experience luxury and functionality with the UB Fleece Cooler Rug - a versatile solution that keeps your horse comfortable..."
    },
    {
        img: "./assets/img/products/equestrian/img_3.jpg",
        icon: "./assets/img/logo_vector.svg",
        title: "Biothane® Bridle",
        content: "Experience the ultimate blend of comfort, convenience, and functionality with our Synthetic Endurance Bridle/Halter Combination..."
    },
    {
        img: "./assets/img/products/equestrian/img_4.jpg",
        icon: "./assets/img/logo_vector.svg",
        title: "Biothane® Snaffle Bridle",
        content: "Elevate your riding experience with our Soft PVC Snaffle Bridle, a masterfully crafted accessory that embodies the perfect fusion..."
    },
    {
        img: "./assets/img/products/equestrian/img_5.jpg",
        icon: "./assets/img/logo_vector.svg",
        title: "Nylon Webbing PP Bridle",
        content: "Our Nylon Webbing Strap Snaffle Bridle with Reins. Perfectly suited for a range of riding styles, from trail and endurance to everyday driving and dressage..."
    },
    {
        img: "./assets/img/products/equestrian/img_6.jpg",
        icon: "./assets/img/logo_vector.svg",
        title: "Biothane® Halter",
        content: "Discover the exceptional quality and innovation of UB's Biothane® Halter—a true testament to our commitment to equine excellence..."
    },
    {
        img: "./assets/img/products/equestrian/img_7.jpg",
        icon: "./assets/img/logo_vector.svg",
        title: "Half Mink Halter",
        content: "Indulge your equine companion in the utmost comfort and luxury with our Half Mink Lined Halter. Meticulously designed for optimal comfort..."
    },
    {
        img: "./assets/img/products/equestrian/img_8.jpg",
        icon: "./assets/img/logo_vector.svg",
        title: "Biothane® Breastplate",
        content: "Embrace the future of equestrian gear with UB. Our Biothane Horse Breastplate not only showcases our dedication to superior craftsmanship..."
    },
    {
        img: "./assets/img/products/equestrian/img_9.jpg",
        icon: "./assets/img/logo_vector.svg",
        title: "Biothane® Reins",
        content: "Experience the ultimate synergy of performance and adaptability with our Super Grip Reins. These reins seamlessly blend the benefits..."
    },
    {
        img: "./assets/img/products/equestrian/img_10.jpg",
        icon: "./assets/img/logo_vector.svg",
        title: "Cotton Lunging Reins",
        content: "Introducing the indispensable Cotton Lunge Line, a must-have training aid for equestrians. Crafted from 1 wide woven cotton fabric..."
    },
]

export { TextSliderData01, TextSliderData02, TextSliderData03 }