const portfolioClassicData = [
  {
    title: "Jumping Leather Saddles",
    img: "/assets/img/portfolio/ubls01.jpg",
    category: ["saddles"],
    subtitle: "UB/LS/01",
    code: "ubls01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Dressage Leather Saddle",
    img: "/assets/img/portfolio/ubls02.jpg",
    category: ["saddles"],
    subtitle: "UB/LS/02",
    code: "ubls02",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Leather Halters",
    img: "/assets/img/portfolio/ublh01.jpg",
    category: ["halters"],
    subtitle: "UB/LH/01",
    code: "ublh01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Leather Snaffle Bridles",
    img: "/assets/img/portfolio/ubljb01.jpg",
    category: ["bridles"],
    subtitle: "UB/LJB/01",
    code: "ubljb01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Belly Guard Jumping Girth",
    img: "/assets/img/portfolio/ublg01.jpg",
    category: ["girth"],
    subtitle: "UB/LG/01",
    code: "ublg01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Anatomic Jumping Girth",
    img: "/assets/img/portfolio/ublg02.jpg",
    category: ["girth"],
    subtitle: "UB/LG/02",
    code: "ublg02",
    link: "/portfolio/single-project-page-01",
  },
];

const portfolioTextilesData = [
  {
    title: "Cotton Sheet Rugs",
    img: "/assets/img/products/ubcs01.jpg",
    category: ["rugs"],
    subtitle: "UB/CS/01",
    code: "ubcs01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Fleece Cooler Rugs",
    img: "/assets/img/products/ubcfr01.jpg",
    category: ["rugs"],
    subtitle: "UB/CFR/01",
    code: "ubcfr01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Fly Mesh Rugs",
    img: "/assets/img/products/ubfmr01.jpg",
    category: ["rugs"],
    subtitle: "UB/FMR/01",
    code: "ubfmr01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Soft Shell Rugs",
    img: "/assets/img/products/ubssr01.jpg",
    category: ["rugs"],
    subtitle: "UB/SSR/01",
    code: "ubssr01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Stable Rugs",
    img: "/assets/img/products/ubstr01.jpg",
    category: ["rugs"],
    subtitle: "UB/STR/01",
    code: "ubstr01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Stable Show Rugs",
    img: "/assets/img/products/ubstr02.jpg",
    category: ["rugs"],
    subtitle: "UB/STR/02",
    code: "ubstr02",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Turnout Rugs",
    img: "/assets/img/products/ubtr01.jpg",
    category: ["rugs"],
    subtitle: "UB/TR/01",
    code: "ubtr01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Turnout 0 Filling",
    img: "/assets/img/products/ubtr02.jpg",
    category: ["rugs"],
    subtitle: "UB/TR/02",
    code: "ubtr02",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "600 D Ripstop Travel Boots",
    img: "/assets/img/products/ubtb01.png",
    category: ["others"],
    subtitle: "UB/TB/01",
    code: "ubtb01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Fleece Saddle Pad",
    img: "/assets/img/products/ubfsp01.png",
    category: ["saddles"],
    subtitle: "UB/FSP/01",
    code: "ubfsp01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Contour shaped Poly cotton saddle Pad",
    img: "/assets/img/products/ubcsp01.png",
    category: ["saddles"],
    subtitle: "UB/CSP/01",
    code: "ubcsp01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Endurance Mesh Saddle Pad",
    img: "/assets/img/products/ubemsp01.png",
    category: ["others"],
    subtitle: "UB/EMSP/01",
    code: "ubemsp01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Cotton Lunging Reins",
    img: "/assets/img/products/ubclr01.png",
    category: ["saddles"],
    subtitle: "UB/CLR/01",
    code: "ubclr01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Equestriam Vest-Body Protector",
    img: "/assets/img/products/ubbp01.png",
    category: ["saddles"],
    subtitle: "UB/BP/01",
    code: "ubbp01",
    link: "/portfolio/single-project-page-01",
  },
];
const portfolioPvcData = [
  {
    title: "Endurance Synthetic Saddle",
    img: "/assets/img/products/ubess01.png",
    category: ["saddle"],
    subtitle: "UB/ESS/01",
    code: "ubess01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Jumping Synthetic Saddle",
    img: "/assets/img/products/ubjss01.png",
    category: ["saddle"],
    subtitle: "UB/JSS/01",
    code: "ubjss01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biothane® Endurance Bridle",
    img: "/assets/img/products/ubbtb01.png",
    category: ["bridels"],
    subtitle: "UB/BTB/01",
    code: "ubbtb01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biothane® Snaffle Bridle",
    img: "/assets/img/products/ubbtjb01.png",
    category: ["bridels"],
    subtitle: "UB/BTJB/01",
    code: "ubbtjb01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biothane® Halter",
    img: "/assets/img/products/ubbth01.png",
    category: ["halters"],
    subtitle: "UB/BTH/01",
    code: "ubbth01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biothane® Breastplate",
    img: "/assets/img/products/ubbtb01.png",
    category: ["others"],
    subtitle: "UB/BTB/01",
    code: "ubbtb01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biothane® Reins",
    img: "/assets/img/products/ubbtr01.png",
    category: ["reins"],
    subtitle: "UB/BTR/01",
    code: "ubbtr01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biothane® Running Martingle",
    img: "/assets/img/products/ubbtrm01.png",
    category: ["others"],
    subtitle: "UB/BTRM/01",
    code: "ubbtrm01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biothane® Stirrup Strap",
    img: "/assets/img/products/ubbtr02.png",
    category: ["others"],
    subtitle: "UB/BTR/01",
    code: "ubbtr02",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Soft Poly Lead Rope",
    img: "/assets/img/products/ubpl01.png",
    category: ["others"],
    subtitle: "UB/PL/01",
    code: "ubpl01",
    link: "/portfolio/single-project-page-01",
  },
];
const portfolioNylonData = [
  {
    title: "Nylon Webbing PP Bridle",
    img: "/assets/img/products/ubpjb01.png",
    category: ["bridels"],
    subtitle: "UB/PJB/01",
    code: "ubpjb01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Mink Fur Halter",
    img: "/assets/img/products/ubmh01.png",
    category: ["halters"],
    subtitle: "UB/MH/01",
    code: "ubmh01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Half Mink Halter",
    img: "/assets/img/products/ubmh02.png",
    category: ["halters"],
    subtitle: "UB/MH/02",
    code: "ubmh02",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Nylon Webbing Halter",
    img: "/assets/img/products/ubph01.png",
    category: ["halters"],
    subtitle: "UB/PH/01",
    code: "ubph01",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Mink Padded Girth",
    img: "/assets/img/products/ubmg02.png",
    category: ["others"],
    subtitle: "UB/MG/01",
    code: "ubmg02",
    link: "/portfolio/single-project-page-01",
  },
];
const portfolioClassicMetroData = [
  {
    title: "Magazine Cover",
    img: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biolator Series",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    img: "https://via.placeholder.com/1000x815",
    category: ["logos"],
    subtitle: "Branding",
    double_col: true,
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "branding"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    img: "https://via.placeholder.com/1000x815",
    category: ["photography"],
    subtitle: "Development",
    double_col: true,
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Armchair Mojo",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography", "branding"],
    subtitle: "E-commerce",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    img: "https://via.placeholder.com/800x650",
    category: ["photography", "branding", "graphics", "logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["photography", "branding", "logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
];

const portfolioClassicMasonryData = [
  {
    title: "Biolator Series",
    img: "https://via.placeholder.com/800x1200",
    category: [""],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    img: "https://via.placeholder.com/800x1200",
    category: ["graphics", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos"],
    subtitle: "Development",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Armchair Mojo",
    img: "https://via.placeholder.com/800x1200",
    category: ["graphics"],
    subtitle: "E-commerce",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
];

const portfolioBoxedData = [
  {
    title: "Love Fashion",
    img: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Creative Tropical",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Foodlly Cafe",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Visionen Gestalten",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Hongo Creative",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Pix Flow",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Emperor Dry",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Herbal Beauty",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Tailoring Interior",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Design Blasting",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Harddot Stone",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioBoxedMasonryData = [
  {
    title: "Love Fashion",
    img: "https://via.placeholder.com/800x1200",
    category: [""],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Creative Tropical",
    img: "https://via.placeholder.com/800x800",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Foodlly Cafe",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Visionen Gestalten",
    img: "https://via.placeholder.com/800x800",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Hongo Creative",
    img: "https://via.placeholder.com/800x1200",
    category: ["photography", "logos"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Pix Flow",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Emperor Dry",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Herbal Beauty",
    img: "https://via.placeholder.com/800x800",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Tailoring Interior",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Design Blasting",
    img: "https://via.placeholder.com/800x800",
    category: ["branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Harddot Stone",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioBoxedMetroData = [
  {
    title: "Mongolish",
    img: "https://via.placeholder.com/1000x815",
    category: [""],
    subtitle: "Branding",
    double_col: true,
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Masscoating",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Cortifiel",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Everyday",
    img: "https://via.placeholder.com/1000x815",
    category: ["branding", "logos", "photography"],
    subtitle: "Brochure",
    double_col: true,
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Scandinavian",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Johnny Walker",
    img: "https://via.placeholder.com/1000x815",
    category: ["graphics"],
    subtitle: "Identity",
    double_col: true,
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Zimmermann",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Gestalten",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlining",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "logos"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
];

const portfolioBorderedData = [
  {
    title: "Motion Picture",
    img: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Waterlly Lake",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Hipster Land",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Stadt Gestalten",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlin Design",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract Comics",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Teabag Collection",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Educamp School",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Thirdeye Glasses",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "logos"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Love Fashion",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Rots Schwarz",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioBorderedMasonryData = [
  {
    title: "Motion Picture",
    img: "https://via.placeholder.com/800x1200",
    category: [""],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Waterlly Lake",
    img: "https://via.placeholder.com/800x800",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Hipster Land",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding", "graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Stadt Gestalten",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "logos"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlin Design",
    img: "https://via.placeholder.com/800x1200",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract Comics",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding", "photography"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Teabag Collection",
    img: "https://via.placeholder.com/800x800",
    category: ["branding", "graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "logos"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Educamp School",
    img: "https://via.placeholder.com/800x800",
    category: ["logos", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Thirdeye Glasses",
    img: "https://via.placeholder.com/800x800",
    category: ["photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Love Fashion",
    img: "https://via.placeholder.com/800x800",
    category: [],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Rots Schwarz",
    img: "https://via.placeholder.com/800x800",
    category: [],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioBorderedMetroData = [
  {
    title: "Mongolish",
    img: "https://via.placeholder.com/1000x815",
    category: [""],
    subtitle: "Branding",
    double_col: true,
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Masscoating",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Cortifiel",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Everyday",
    img: "https://via.placeholder.com/1000x815",
    category: ["branding", "photography"],
    subtitle: "Brochure",
    double_col: true,
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Scandinavian",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Johnny Walker",
    img: "https://via.placeholder.com/1000x815",
    category: ["graphics"],
    subtitle: "Identity",
    double_col: true,
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Zimmermann",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Gestalten",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlining",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "logos"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
];

const portfolioColorfulData = [
  {
    title: "Mongolish",
    img: "https://via.placeholder.com/800x1000",
    category: [""],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Masscoating",
    img: "https://via.placeholder.com/800x1000",
    category: ["graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Cortifiel",
    img: "https://via.placeholder.com/800x1000",
    category: ["branding", "logos"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Everyday",
    img: "https://via.placeholder.com/800x1000",
    category: ["branding", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Scandinavian",
    img: "https://via.placeholder.com/800x1000",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x1000",
    category: ["graphics"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Johnny Walker",
    img: "https://via.placeholder.com/800x1000",
    category: ["logos", "photography"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Zimmermann",
    img: "https://via.placeholder.com/800x1000",
    category: ["branding"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Gestalten",
    img: "https://via.placeholder.com/800x1000",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlining",
    img: "https://via.placeholder.com/800x1000",
    category: ["branding", "photography"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract",
    img: "https://via.placeholder.com/800x1000",
    category: ["graphics", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Teabag",
    img: "https://via.placeholder.com/800x1000",
    category: ["graphics", "photography"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioColorfulMasonryData = [
  {
    title: "Mongolish",
    img: "https://via.placeholder.com/800x800",
    category: [""],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Masscoating",
    img: "https://via.placeholder.com/800x1200",
    category: ["graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Cortifiel",
    img: "https://via.placeholder.com/800x800",
    category: ["branding"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Everyday",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding", "logos"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Scandinavian",
    img: "https://via.placeholder.com/800x1200",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x1200",
    category: ["graphics"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Johnny Walker",
    img: "https://via.placeholder.com/800x800",
    category: ["logos", "photography"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Zimmermann",
    img: "https://via.placeholder.com/800x800",
    category: ["branding"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Gestalten",
    img: "https://via.placeholder.com/800x800",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlining",
    img: "https://via.placeholder.com/800x800",
    category: ["branding", "photography"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Teabag",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioColorfulMetroData = [
  {
    title: "Mongolish",
    img: "https://via.placeholder.com/1000x815",
    category: [""],
    subtitle: "Branding",
    double_col: true,
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Masscoating",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Cortifiel",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Everyday",
    img: "https://via.placeholder.com/1000x815",
    category: ["branding", "logos", "photography"],
    subtitle: "Brochure",
    double_col: true,
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Scandinavian",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Johnny Walker",
    img: "https://via.placeholder.com/1000x815",
    category: ["graphics"],
    subtitle: "Identity",
    double_col: true,
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Zimmermann",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Gestalten",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlining",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "logos"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
];

const PortfolioOverlayData = [
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Waterlly Lake",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Bill Gardner",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Jeremy Dupont",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Armchair Mojo",
    img: "https://via.placeholder.com/800x650",
    category: ["Photography", "branding"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Manchester",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "branding"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Everyday Needs",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "graphics"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "branding"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Designblast Design",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioOverlayMesonryData = [
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x800",
    category: [""],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Waterlly Lake",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Jeremy Dupont",
    img: "https://via.placeholder.com/800x800",
    category: ["branding", "graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Bill Gardner",
    img: "https://via.placeholder.com/800x1200",
    category: ["logos", "graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "The Manchester",
    img: "https://via.placeholder.com/800x1200",
    category: ["Photography", "logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Armchair Mojo",
    img: "https://via.placeholder.com/800x1200",
    category: ["photography", "branding"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Truenorth Web",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "branding"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x800",
    category: ["logos", "graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x800",
    category: ["logos", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Everyday Needs",
    img: "https://via.placeholder.com/800x800",
    category: ["photography", "branding"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Designblast Design",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioOverlayMetroData = [
  {
    title: "Mongolish",
    img: "https://via.placeholder.com/1000x815",
    category: [""],
    subtitle: "Branding",
    double_col: true,
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Masscoating",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Cortifiel",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Everyday",
    img: "https://via.placeholder.com/1000x815",
    category: ["branding", "photography"],
    subtitle: "Brochure",
    double_col: true,
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Scandinavian",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Johnny Walker",
    img: "https://via.placeholder.com/1000x815",
    category: ["graphics"],
    subtitle: "Identity",
    double_col: true,
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Zimmermann",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Gestalten",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlining",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "logos"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
];

const portfolioSwitchImgData = [
  {
    title: "Magazine Cover",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Design and create brand",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biolator Series",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Most wonderful place",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "You have a good family",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos"],
    subtitle: "These are good suggestions",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Carmen is a good wife",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Everything was in good",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Quit talking and begin doing",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos"],
    subtitle: "Opportunity in every difficulty",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Armchair Mojo",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "You learn more from failure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "You are working on something",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Mass Productions",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Cortifiel Look",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Imagine your life",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioSwitchImgMesonryData = [
  {
    title: "Magazine Cover",
    frontimg: "https://via.placeholder.com/800x1200",
    backimg: "https://via.placeholder.com/800x1200",
    category: [""],
    subtitle: "Most wonderful place",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biolator Series",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: ["branding"],
    subtitle: "Design and create brand",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Daimler Financial",
    frontimg: "https://via.placeholder.com/800x1200",
    backimg: "https://via.placeholder.com/800x1200",
    category: ["branding", "graphics"],
    subtitle: "These are good suggestions",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Potato Oslands",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: ["graphics", "logos"],
    subtitle: "You have a good family",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    frontimg: "https://via.placeholder.com/800x1200",
    backimg: "https://via.placeholder.com/800x1200",
    category: ["logos", "photography"],
    subtitle: "Carmen is a good wife",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    frontimg: "https://via.placeholder.com/800x1200",
    backimg: "https://via.placeholder.com/800x1200",
    category: ["branding", "photography"],
    subtitle: "Everything was in good",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: ["branding", "graphics"],
    subtitle: "Quit talking and begin doing",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: ["graphics", "logos"],
    subtitle: "Opportunity in every difficulty",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Truenorth Web",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: ["logos", "photography"],
    subtitle: "You are working on something",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Armchair Mojo",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: ["photography"],
    subtitle: "You learn more from failure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Cortifiel Look",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: [""],
    subtitle: "Imagine your life",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Mass Productions",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: [""],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioSwitchImgMetroData = [
  {
    title: "Magazine Cover",
    frontimg: "https://via.placeholder.com/1000x815",
    backimg: "https://via.placeholder.com/1000x815",
    category: [""],
    subtitle: "Design and create brand",
    double_col: true,
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biolator Series",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Most wonderful place",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "You have a good family",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    frontimg: "https://via.placeholder.com/1000x815",
    backimg: "https://via.placeholder.com/1000x815",
    category: ["branding", "photography"],
    subtitle: "These are good suggestions",
    double_col: true,
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Carmen is a good wife",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Everything was in good",
    link: "/portfolio/single-project-page-01",
  },

  {
    title: "Bill Gardner",
    frontimg: "https://via.placeholder.com/1000x815",
    backimg: "https://via.placeholder.com/1000x815",
    category: ["graphics"],
    subtitle: "Quit talking and begin doing",
    double_col: true,
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Opportunity in every difficulty",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Armchair Mojo",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "You learn more from failure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "logos"],
    subtitle: "You are working on something",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Mass Productions",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Crop Identity",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Cortifiel Look",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Educamp School",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-05",
  },
];

const portfolioSliderData = [
  {
    title: "Simply Apple",
    subtitle: "Create brand",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "SBiolator Series",
    subtitle: "Wonderful place",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    subtitle: "Good family love",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    subtitle: "Good suggestions",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    subtitle: "Carmen wife",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    subtitle: "Everything good",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    subtitle: "Quit talking",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioScatteredData = [
  {
    title: "Magazine Cover",
    img: "https://via.placeholder.com/800x550",
    subtitle: "Photography",
    category: [""],
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biolator Series",
    img: "https://via.placeholder.com/800x1070",
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    img: "https://via.placeholder.com/800x550",
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x1070",
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    img: "https://via.placeholder.com/800x1070",
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    img: "https://via.placeholder.com/800x550",
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    img: "https://via.placeholder.com/800x1070",
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    img: "https://via.placeholder.com/800x550",
    subtitle: "Development",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Armchair Mojo",
    img: "https://via.placeholder.com/800x550",
    subtitle: "E-commerce",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    img: "https://via.placeholder.com/800x1070",
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x550",
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x1070",
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioJustifiedGalleryData = [
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1000",
    category: [""],
    subtitle: "Armchair",
  },
  {
    title: "Photography",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Identity",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding"],
    subtitle: "Educamp",
  },
  {
    title: "Web",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Cortifiel",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1000",
    category: ["photography"],
    subtitle: "Instacuppa",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1000",
    category: ["logos"],
    subtitle: "Pendant",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1200",
    category: ["graphics"],
    subtitle: "Everyday",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1000",
    category: ["logos"],
    subtitle: "Instacuppa",
  },
  {
    title: "Photography",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Henson",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "photography"],
    subtitle: "Willmann",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Willmann",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1200",
    category: ["graphics", "photography"],
    subtitle: "Nothing",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1000",
    category: ["graphics", "photography"],
    subtitle: "Pritchard",
  },
  {
    title: "Photography",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Herbally",
  },
  {
    title: "Brochure",
    img: "https://via.placeholder.com/1000x815",
    category: ["graphics", "photography"],
    subtitle: "Adapter",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Whistles",
  },
  {
    title: "Photography",
    img: "https://via.placeholder.com/800x1000",
    category: ["graphics", "photography"],
    subtitle: "Harddot",
  },
];

const FilterData = [
  {
    title: "All",
    key: "*",
  },
  {
    title: "SADDLES",
    key: "saddles",
  },
  {
    title: "HALTERS",
    key: "halters",
  },
  {
    title: "BRIDLES",
    key: "bridles",
  },
  {
    title: "GIRTH",
    key: "girth",
  },
];

const FilterTextileData = [
  {
    title: "All",
    key: "*",
  },
  {
    title: "SADDLES",
    key: "saddles",
  },
  {
    title: "HALTERS",
    key: "halters",
  },
  {
    title: "RUGS",
    key: "rugs",
  },
  {
    title: "PROTECTOR",
    key: "protector",
  },
  {
    title: "OTHERS",
    key: "others",
  },
];
const landingPageFilterData = [
  {
    title: "All",
    key: "*",
  },
  {
    title: "CORPORATE",
    key: "corporate",
  },
  {
    title: "SPECIALIZED",
    key: "specialized",
  },
  {
    title: "PORTFOLIO",
    key: "portfolio",
  },
  {
    title: "E-COMMERCE",
    key: "e-commerce",
  },
  {
    title: "BLOG",
    key: "blog",
  },
];

export {
  portfolioClassicData,
  portfolioTextilesData,
  portfolioPvcData,
  portfolioNylonData,
  portfolioClassicMetroData,
  portfolioClassicMasonryData,
  portfolioBoxedData,
  portfolioBoxedMasonryData,
  portfolioBoxedMetroData,
  portfolioBorderedData,
  portfolioBorderedMetroData,
  portfolioBorderedMasonryData,
  portfolioColorfulData,
  portfolioColorfulMasonryData,
  portfolioColorfulMetroData,
  PortfolioOverlayData,
  portfolioOverlayMesonryData,
  portfolioOverlayMetroData,
  portfolioSwitchImgData,
  portfolioSwitchImgMesonryData,
  portfolioSwitchImgMetroData,
  portfolioSliderData,
  portfolioScatteredData,
  portfolioJustifiedGalleryData,
  FilterData,
  FilterTextileData,
  landingPageFilterData,
};
